@import '../../vars.scss';

.notificationWrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  width: $notificationWidth;
  z-index: 9999;
}

.notificationItem {
  box-shadow: 0 0 10px rgba(0,0,0, 0.3);
  border-radius: 0.8rem;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
  background-color: #222;
  width: $notificationWidth;
}

@keyframes SlideLeft {
  0% {
      margin-left: 120%;
  }

  100% {
      margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
      margin-left: 0;
  }

  100% {
      margin-left: 120%;
  }
}

.notificationItem.exit {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}

.notificationItem p {
  margin: 0;
  padding: 1.0rem 2.0rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #cbc2d4;
}

.notificationItem .bar {
  height: 0.5rem;
}

.notificationItem.success .bar {
  background-color: $valid;
}

.notificationItem.error .bar {
  background-color: $error;
}
