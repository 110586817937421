@import "../../vars.scss";

.dropdown {
  position: relative;
  .dropdownMenu {
    display: none;
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 1.6rem 0;
    position: absolute;
    top: calc(100% + 0.2rem);
    min-width: 20.0rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5), 0 6px 6px rgba(0,0,0,0.8);
    z-index: 9999;
    a, button {
      font-size: 1.8rem;
      padding: 1.6rem 1.6rem;
      color: $secondary;
      cursor: pointer;
      font-weight: 500;
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: flex-start;
      border-radius: 0;
      &:hover {
        background-color: lighten($secondary, 55%);
      }
    }
    &.dropdownMenuOpen {
      display: block;
    }
  }
  .dropdownTriggerOpen {
    a, button {
      background-color: $primary;
    }
  }
}
