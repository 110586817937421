.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.0rem;
  overflow-y: auto;
  background-color: #000;
  user-select: none;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.containerMaster {
  position: fixed;
  top: 0;
  left: 0;
  padding: 2.0rem;
  overflow-y: auto;
  background-color: red;
  user-select: none;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  width: 250px;
  height: 350px;
  z-index: 1;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
.containerSlave {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.0rem;
  overflow-y: auto;
  background-color: #000;
  user-select: none;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.statusBar {
  background-color: #3A3A3A;
  color: #fff;
  font-size: 1.4rem;
  padding: 1.0rem 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  text-transform: uppercase;
  z-index: 11;
  ul {
    display: flex;
    li {
      padding: 0 1.0rem;
    }
  }
}
