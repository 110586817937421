@import '../../vars.scss';

.btn {
  display: flex;
  height: 4.8rem;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  width: 100%;
  padding: 0 3.2rem;
  font-size: 1.8rem;
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.0, 0.0, 0.2, 1); // Decelerate easing (Outgoing Easing: 0%, Incoming Easing: 80%)
}

.primary {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: $btnPrimary;
  color: #fff;
  &:hover {
    background-color: lighten($btnPrimary, 10%);
  }
}

.danger {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: $btnDanger;
  color: #fff;
  &:hover {
    background-color: lighten($btnDanger, 10%);
  }
}

.secondary {
  background-color: $btnSecondary;
  color: #cbc2d4;
  &:hover {
    color: #fff;
    background-color: lighten($btnSecondary, 10%);
  }
}

.disabled {
  background-color: $btnDisabled;
  color: #fff;
  pointer-events: none;
  &:hover {
    background-color: lighten($btnDisabled, 10%);
  }
}

.btnCircle{
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  padding: 0;
}

.link {
  color: $btnLinkColor;
  font-size: 1.6rem;
  &:hover {
    color: lighten($btnLinkColor, 10%);
  }
}

.default {
  background-color: red;
}
