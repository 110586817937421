@import '../../vars.scss';

.navigation {
  background-color: $navbar;
  height: $navbarHeight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.2rem;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  h1 {
    font-size: 3.2rem;
    text-transform: uppercase;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .right {
    width: 13.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: flex-end;
    transition: all 0.2s ease-in-out;
  }
}

.scroll {
  background-color: #171717;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  height: 7.0rem;
  h1 {
    font-size: 2.1rem;
  }
  button, a {
    height: 4.8rem;
    width: 4.8rem;
    i {
      font-size: 2.4rem!important;
    }
  }
  .right {
    width: 11.2rem;
  }
}