.modal {
  display: none;
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 2.4rem;
  position: fixed;
  top: 4.8rem;
  width: 50%;
  left: 50%;
  margin-left: -25%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.5), 0 6px 6px rgba(0,0,0,0.8);
  z-index: 9999;
}

.backdrop {
  position: fixed;
  background-color: rgba(0,0,0,0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
}

.title {
  font-size: 2.1rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 3.2rem;
}
