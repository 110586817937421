.title {
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2.4rem;
}

.container {
  max-width: 60.0rem;
  margin: 0 auto;
}

.section{
  margin-bottom: 4.8rem;
  background-color: #171717;
  padding: 2.4rem;
}

.text {
  color: #fff;
  font-size: 1.8rem;
  text-align: center;
  margin-top: 3.2rem;
  text-align: center;
}
