@import "../../vars.scss";

.container {
  position: relative;
  * {
    transition: all 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  .selected {
    color: #fff;
    height: 4.8rem;
    background-color: $secondary;
    border-radius: 0.8rem;
    padding: 0 2.4rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    .label {
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 1.0rem;
      color: #828282;
    }
    &.listOpen{
      background-color: $primary;
      &:hover {
        background-color: lighten($primary, 10%);
      }
      .label {
        color: #fff;
      }
    }
    &:hover {
      background-color: lighten($secondary, 10%);
    }
  }

  .list {
    display: none;
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 1.6rem 0;
    position: absolute;
    top: calc(100% + 0.2rem);
    left:0;
    min-width: 20.0rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5), 0 6px 6px rgba(0,0,0,0.8);
    z-index: 9999;
    &.listOpen {
      display: block;
    }
  }

  .listItem {
    font-size: 1.8rem;
    padding: 1.6rem 1.6rem;
    color: $secondary;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    &:hover {
      background-color: lighten($secondary, 55%);
    }
  }
}

.toolbarContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary;
  border-radius: 0.8rem;
  .container {
    .selected {
      border-radius: 0;
    }
  }
}
