// Sans-serif
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap'); // App
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap'); // font-family: 'Roboto Mono', monospace;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap'); // font-family: 'Open Sans', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'); // font-family: 'Lato', sans-serif;
// serif
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap'); // font-family: 'Merriweather', serif;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap'); // font-family: 'Playfair Display', serif;
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap'); // font-family: 'Bree Serif', serif;

@import './vars.scss';
@import './reset.scss';

body, html {
  background-color: $mainBackgroundColor;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 62.5%; // 1.2rem will be 12px
  font-family: 'Roboto', sans-serif;
}

body {
  margin-top: $navbarHeight;
}

a {
  text-decoration: none;
}

* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

*:focus, *:visited, *:active, *:hover  { outline:0 !important;}
*::-moz-focus-inner {border:0;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


