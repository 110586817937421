@import '../../vars.scss';

.container {
  margin-top: 1.6rem;
  .label {
    cursor: pointer;
    position: relative;
    margin-bottom: 3.2rem;
    display: flex;
    flex-direction: column-reverse;
    .labelText {
      position: absolute;
      top: -1.6rem;
      left:0;
      right: 0;
      color: $text;
      font-size: $labelSize;
      padding-bottom: 0.4rem;
      opacity: 0;
      font-weight: 600;
      transition: color 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 4.8rem;
      height: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    // Set hover state for input when hovering over label
    &:hover {
      .labelText {
        color: $textHoverFocused;
      }
      .textarea {
        background-color: $inputBackgroundHover;
        border-color: $inputBackgroundHover;
        color: $textHoverFocused;
      }
    }

    // Set hover state for label when hovering over input
    .textarea:hover + .labelText {
      color: $textHoverFocused;
    }
    .textarea:focus + .labelText {
      color: $textHoverFocused;
    }

    .textarea {
      background-color: $inputBackground;
      min-height: 25.0rem;
      padding: 2.4rem;
      border-radius: 0.8rem;
      border: 2px solid $inputBackground;
      font-size: $textSize;
      font-weight: 400;
      width: 100%;
      color: $text;
      caret-color: $text;
      resize: none;
      transition: all 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
      transition-property: drop-shadow, background-color, border;
      &:hover {
        background-color: $inputBackgroundHover;
        border-color: $inputBackgroundHover;
        color: $textHoverFocused;
        caret-color: $textHoverFocused;
        &::placeholder {
          opacity: $placeholderHoverOpacity;
        }
      }
      &:focus {
        background-color: $inputBackground;
        border-color: $borderFocused;
        // Using filter instead of box-shadow to avoid conflict with autofill box shadow
        filter: drop-shadow(0 0 0.6rem $borderFocused);
        color: $textHoverFocused;
        caret-color: $textHoverFocused;
        cursor: auto;
        // autofilled uses box shadow for bg color while normal input uses
        // bg color, by limiting transition to filter the user won't see the flash of color when
        // switching from box shadow to shadow, We can't use box shadow for normal input
        // because it will cover input manipulators like lastPass
        // transition: filter 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
        &::placeholder {
          opacity: $placeholderHoverOpacity;
        }
      }
      &::placeholder {
        color: $placeholder;
        opacity: $placeholderOpacity;
      }
    }
  }

  // This controls the float labels
  &.textareaFilled {
    .label .labelText {
      opacity: 1;
    }
  }
  &.textareaEmpty {
    .label .labelText {
      opacity: 0;
    }
  }
}
