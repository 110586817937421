@import '../../vars.scss';

.noContent{
  width:300px;
  margin: 2.0rem auto;
  text-align:center;
  p{
    font-size: 1.8rem;
    color: $text;
    margin-top: 1.0rem;
    margin-bottom: 2.0rem;
  }
}
