@import '../../vars.scss';

.list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -1.6rem;
  .listItem {
    background-color: #3A3A3A;
    border-radius: 0.8rem;
    padding: 3.2rem;
    width: calc(33.3333333333% - 3.2rem);
    margin: 0 1.6rem 3.2rem 1.6rem;
    transition: all 0.2s ease-in-out;
    h1 {
      font-size: 3.2rem;
      font-weight: 500;
      color: #fff;
      margin-bottom: 1.6rem;
    }
    p {
      font-size: 2.4rem;
      color: #D2D2D2;
      font-weight: 400;
      line-height: 3.2rem;
    }
    &:hover {
      background-color: lighten(#3A3A3A, 10%);
    }
  }
}
