@import '../../vars.scss';

.slider {
  -webkit-appearance: none;
  width: 25.0rem;
  height: 24px;
  background: $secondary;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 1.6rem;
  &:hover {
    background: lighten($secondary, 10%);
  }
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  &:hover{
    background: lighten($primary, 10%);
  }
}

.slider::-moz-range-thumb {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  &:hover{
    background: lighten($primary, 10%);
  }
}
