// colors
$primary: #449330;
$secondary: #3A3A3A;
// Main
$mainBackgroundColor: #202020;
$navbar: transparent;
$navbarHeight: 13.6rem;
$container: transparent;
// Input
$inputBackground: #303030;
$inputBackgroundHover: lighten($inputBackground, 5%);
$placeholder: #D7D7D7;
$placeholderOpacity: 0.6;
$placeholderHoverOpacity: 0.7;
$textHoverFocused: #fff;
$borderFocused: $primary;
$labelSize: 1.2rem;
$helpTextSize: 1.2rem;
// Notification
$notificationWidth: 30.0rem;
// Button
$btnPrimary: $primary;
$btnSecondary: $secondary;
$btnLinkColor: #cbc2d4;
$btnDanger: #933030;
$btnDisabled: #585858;
// Header
$headerColor: #cbc2d4;
// Genera
$text: #cbc2d4;
$textSize: 1.6rem;
$error: #e53935;
$valid: #02ff6c;
$icon: #0090FF;
$fontFamily: 'Roboto', sans-serif;
