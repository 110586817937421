@import '../../vars.scss';

/**
  The normal background color is overwritten by google stupid autofill styling
  I solved this by using box-shadow for '-webkit-autofill'
  (this is the best solution at this time 2021)

  Since autofill loads after the component is rendered I set a timeout (component render + 1s)
  after which I remove preload, this prevents the box shadow to have an ugly initial transition.
  Since box shadow should not be used for this when autofill loads transitioning will cause
  the user to see how the shadow fills in the box from the sides of the input towards to the center.
**/

.preload * {
  transition: none !important;
}

.noSpacing {
  margin-top: 0;
  .label {
    margin-bottom: 0;
  }
}

.container {
  margin-top: 1.6rem;
  &.noSpacing {
    margin-top: 0;
    .label {
      margin-bottom: 0;
    }
  }
  .label {
    cursor: pointer;
    position: relative;
    display: block;
    margin-bottom: 3.2rem;
    display: flex;
    flex-direction: column-reverse;
    .labelText {
      position: absolute;
      top: -1.6rem;
      left:0;
      right: 0;
      color: $text;
      font-size: $labelSize;
      padding-bottom: 0.4rem;
      opacity: 0;
      font-weight: 600;
      transition: color 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 4.8rem;
      height: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    // Set hover state for input when hovering over label
    &:hover {
      .labelText {
        color: $textHoverFocused;
      }
      .input {
        background-color: $inputBackgroundHover;
        border-color: $inputBackgroundHover;
        color: $textHoverFocused;
      }
      // FIX: This will make sure that when the user hovers over the label
      // the input hover style will be the same even for autofilled input (chrome)
      .input:-webkit-autofill {
        -webkit-text-fill-color: $textHoverFocused !important; // overwrite clipped color
        -webkit-box-shadow: 0 0 0 30px $inputBackgroundHover inset !important; // Sets bg color
      }

      // FIX: when autofilled input is focused and user hovers autofill hover state is triggered
      // this makes sure that focused styling stays the same even for autofilled input
      .input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px $inputBackground inset !important; // Sets bg color
      }
    }

    // Set hover state for label when hovering over input
    .input:hover + .labelText {
      color: $textHoverFocused;
    }
    .input:focus + .labelText {
      color: $textHoverFocused;
    }

    .input {
      background-color: $inputBackground;
      height: 4.8rem;
      padding: 0 2.4rem;
      border-radius: 0.8rem;
      border: 2px solid $inputBackground;
      font-size: $textSize;
      font-weight: 400;
      width: 100%;
      color: $text;
      caret-color: $text;
      transition: all 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
      &.inputIcon {
        padding: 0 4.8rem;
      }
      &:hover {
        background-color: $inputBackgroundHover;
        border-color: $inputBackgroundHover;
        color: $textHoverFocused;
        caret-color: $textHoverFocused;
        &::placeholder {
          opacity: $placeholderHoverOpacity;
        }
      }
      &:focus {
        background-color: $inputBackground;
        border-color: $borderFocused;
        // Using filter instead of box-shadow to avoid conflict with autofill box shadow
        filter: drop-shadow(0 0 0.6rem $borderFocused);
        color: $textHoverFocused;
        caret-color: $textHoverFocused;
        cursor: auto;
        // autofilled uses box shadow for bg color while normal input uses
        // bg color, by limiting transition to filter the user won't see the flash of color when
        // switching from box shadow to shadow, We can't use box shadow for normal input
        // because it will cover input manipulators like lastPass
        transition: filter 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
        &::placeholder {
          opacity: $placeholderHoverOpacity;
        }
      }
      &::placeholder {
        color: $placeholder;
        opacity: $placeholderOpacity;
      }
    }
  }

  // Make autofilled input look the same as the normal inputs
  .input:-webkit-autofill{
    -webkit-background-clip: text;
    -webkit-text-fill-color: $text;
    -webkit-box-shadow: 0 0 0 30px $inputBackground inset;
  }

  // Make autofilled hover state the same as the normal inputs
  .input:-webkit-autofill:hover{
    -webkit-text-fill-color: $textHoverFocused;
    -webkit-box-shadow: 0 0 0 30px $inputBackgroundHover inset;
  }

  // Make autofilled focus state the same as the normal inputs
  .input:-webkit-autofill:focus{
    -webkit-text-fill-color: $textHoverFocused;;
  }

  // Make text filled from autofill dropdown looks the same as the normal inputs
  .input:-webkit-autofill::first-line {
    font-size: $textSize;
    font-weight: 400;
    font-family: $fontFamily;
  }

  // This controls the float labels
  &.inputFilled {
    .label .labelText {
      opacity: 1;
    }
  }
  &.inputEmpty {
    .label .labelText {
      opacity: 0;
    }
  }
}
