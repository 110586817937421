.container {
  width: 40.0rem;
  margin: 0 auto;
}

.header {
  color: #fff;
  font-size: 2.1rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3.2rem;
}
